import React, { Suspense, useContext } from "react";
import { Grid, Modal, Box, CardActionArea } from "@mui/material";
import {
  DeploymentsByUsers,
  DeploymentsByPeriod,
  DeploymentsCalendarView,
  DeploymentsByStatus,
  DashboardStats,
} from "../components/charts";
import StatSkeleton from "../components/common/StatSkeleton";
import Check from "../auth/ability";
import DottedTile from "../components/common/DottedTile";
import { grey } from "@mui/material/colors";
import { CAN, SUBJECTS } from "../auth/permissions";
import { MODULES } from "../services/Constants";
import useModule from "../hooks/useModule";
import { ACTIONS, WizardContext } from "../components/wizard/Context";
import RocketLaunchIcon from "@mui/icons-material/RocketLaunch";

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 1000,
  bgcolor: 'background.paper',
  margin: 0,
  padding: 0,
  p: 4,
};

const Dashboard = () => {
  const [open, setOpen] = React.useState('');
  const handleClose = () => setOpen('');
  const { validateModuleWithMessage } = useModule();
  const { dispatch } = useContext(WizardContext);
  return (
    <div className="fragmentContainer">
      <Grid container spacing={2}>
        <Suspense fallback={<StatSkeleton tiles={4} height={80} />}>
          <DashboardStats />
        </Suspense>
        <Check I={CAN.CREATE} a={SUBJECTS.DEPLOYMENT}>
          <Grid item xs={12} sm={6} md={2}>
            <CardActionArea
              onClick={() => {
                if (validateModuleWithMessage(MODULES.OTA)) {
                  dispatch({
                    type: ACTIONS.TOGGLE_WIZARD,
                    payload: {
                    showWizard: true,
                    },
                  })
                }
              }}
            >
              <DottedTile title={"CREATE A DEPLOYMENT"}>
                <RocketLaunchIcon
                  sx={{ fontSize: "5.4rem", color: grey[500] }}
                />
              </DottedTile>
            </CardActionArea>
          </Grid>
        </Check>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <DeploymentsCalendarView />
        </Grid>
        <Grid item xs={12} sm={12} md={3} lg={3}>
          <DeploymentsByStatus setOpen={setOpen} />
        </Grid>
        <Grid item xs={12} sm={12} md={3} lg={3}>
          <DeploymentsByUsers setOpen={setOpen} />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <DeploymentsByPeriod />
        </Grid>
      </Grid>
      {open && <Modal
        open={open ? true : false}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          {open === 'user' && <DeploymentsByUsers popup setOpen={setOpen} />}
          {open === 'status' && <DeploymentsByStatus popup setOpen={setOpen} />}
        </Box>
      </Modal>}
    </div>
  );
};

export default Dashboard;
