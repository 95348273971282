import axios from "axios";
import Constants from "../services/Constants";
import { constructQueryUrl } from "./Utils";

export const getDeploymentData = async ({ queryKey }) => {
  const [
    _,
    page,
    pageSize,
    field,
    sort,
    filterValue,
    columns,
    extraFilters,
    fetchAll,
  ] = queryKey;

  let query =
    Constants.LIST_DEPLOYMENTS +
    `?custid=${
      localStorage?.selectedCustomer
    }&page=${page}&take=${pageSize}&sortby=${field}&order=${sort.toUpperCase()}`;

  if (fetchAll) {
    query = `${query}&fetchAll=true`;
  }

  query = constructQueryUrl({ filterValue, query, columns });
  return await axios.get(query);
};

export const createDeployment = async (payload) => {
  return await axios.post(Constants.ADD_DEPLOYMENT, payload);
};

export const getReleaseData = async () => {
  return await axios.get(Constants.LIST_RELEASES);
};

export const getAllReleases = async () => {
  return await axios.get(Constants.LIST_RELEASES_FOR_DEPLOYMENT);
};

export const getVehicleData = async ({ queryKey }) => {
  const [
    _,
    page = 1,
    pageSize = 50,
    field = "serialNoCreatedAt",
    sort = "DESC",
    filterValue = {},
    columns,
    extraFilters = [],
    fetchAll,
    allVehicles,
  ] = queryKey;
  const newFilterValue = JSON.parse(JSON.stringify(filterValue));
  try {
    if (newFilterValue?.items) {
      newFilterValue.items = [...filterValue.items, ...extraFilters];
    } else {
      newFilterValue["items"] = [...extraFilters];
    }
  } catch (e) {
    console.log(e);
  }
  let query =
    Constants.LIST_VEHICLES +
    `?custid=${
      localStorage?.selectedCustomer
    }&page=${page}&take=${pageSize}&sortby=${field}&order=${sort.toUpperCase()}`;

  if (allVehicles) {
    query = `${query}&allVehicles=true`;
  }

  if (fetchAll) {
    query = `${query}&fetchAll=true`;
  }

  query = constructQueryUrl({ filterValue: newFilterValue, query, columns });
  return await axios.get(query);
};

export const getActiveVehiclesByQuery = async ({ queryKey }) => {
  const [
    _,
    page = 1,
    pageSize = 100,
    field = "serialNoCreatedAt",
    sort = "DESC",
    extraFilters = [],
    columns = [],
  ] = queryKey;
  const newFilterValue = {};
  try {
    newFilterValue["items"] = [
      ...extraFilters,
      ...[
        {
          columnField: "active",
          operatorValue: "equals",
          value: true,
        },
      ],
    ];
  } catch (e) {
    console.log(e);
  }
  let query =
    Constants.LIST_VEHICLES +
    `?page=${page}&take=${pageSize}&sortby=${field}&order=${sort.toUpperCase()}`;

  query = constructQueryUrl({ filterValue: newFilterValue, query, columns });
  return await axios.get(query);
};

export const getVehiclesInGroup = async ({ queryKey }) => {
  const [
    _,
    page = 1,
    pageSize = 100,
    field = "serialNoCreatedAt",
    sort = "DESC",
    groupId,
    searchString,
    fetchAll,
  ] = queryKey;
  let query =
    Constants.LIST_VEHICLES_IN_GROUP +
    `/${groupId}?page=${page}&take=${pageSize}&sortby=${field}&order=${sort.toUpperCase()}&searchstring=${searchString}`;

  if (fetchAll) {
    query = `${query}&fetchAll=true`;
  }
  return await axios.get(query);
};

export const getVehicleGroupData = async () => {
  const { data } = await axios.get(Constants.LIST_VEHICLE_GROUPS);
  return data;
};

export const getDeploymentDetails = async ({ queryKey }) => {
  const [_, deploymentId] = queryKey;
  const url = Constants.DESCRIBE_DEPLOYMENT.replace(":id", deploymentId);
  return await axios.get(url);
};

export const getDeploymentVehicleLogDetails = async ({ queryKey }) => {
  const [_, deploymentId] = queryKey;
  const url = Constants.DEPLOYMENT_VEHICLELOG.replace(":id", deploymentId);
  return await axios.get(url);
};

export const getDeploymentVehicleLogDetails_v1 = async ({ queryKey }) => {
  const [_, deploymentId] = queryKey;
  const url = Constants.DEPLOYMENT_VEHICLELOG_V1.replace(":id", deploymentId);
  return await axios.get(url);
};

export const getJobDocument = async ({ queryKey }) => {
  const [_, deploymentId] = queryKey;
  const url = Constants.JOB_DOCUMENT_FOR_DEPLOYMENT_ID.replace(
    ":id",
    deploymentId
  );
  return await axios.get(url);
};

export const getJobDetails = async ({ queryKey }) => {
  const [_, deploymentId] = queryKey;
  const url = Constants.JOB_DETAILS_FOR_DEPLOYMENT_ID.replace(
    ":id",
    deploymentId
  );
  return await axios.get(url);
};

export const getJobStatus = async ({ queryKey }) => {
  const [_, deploymentId] = queryKey;
  const url = Constants.JOB_STATUS_FOR_DEPLOYMENT_ID.replace(
    ":id",
    deploymentId
  );
  return await axios.get(url);
};

export const getJobStatusV1 = async ({ queryKey }) => {
  const [_, deploymentId] = queryKey;
  const url = Constants.JOB_STATUS_FOR_DEPLOYMENT_ID_V1.replace(
    ":id",
    deploymentId
  );
  return await axios.get(url);
};

export const publishDeployment = async (deploymentId) => {
  return await axios.post(Constants.PUBLISH_DEPLOYMENTS, {
    deploymentId: deploymentId,
  });
};

export const cancelDeployment = async (deploymentId) => {
  return await axios.post(Constants.CANCEL_DEPLOYMENTS, {
    deploymentId: deploymentId,
  });
};

export const cancelVehicleDeployment = async (payload) => {
  return await axios.post(Constants.CANCEL_VEHICLE_DEPLOYMENTS, payload);
};

export const validateDeploymentName = async ({ queryKey }) => {
  const [_, deploymentName] = queryKey;
  const url = Constants.VALIDATE_DEPLOYMENT.replace(":name", deploymentName);
  return await axios.get(url);
};

export const validateConfigName = async ({ queryKey }) => {
  const [_, configName] = queryKey;
  const url = Constants.VALIDATE_CONFIG.replace(":name", configName);
  return await axios.get(url);
};

// Get all things in a group by using the name of the group.
export const getThingsInGroup = async ({ queryKey }) => {
  const [_, groupName] = queryKey;
  if (groupName && groupName.length) {
    const url = Constants.GET_THINGS_IN_GROUP.replace(":groupName", groupName);
    return await axios.get(url);
  } else {
    return {};
  }
};
