import React, { useState } from "react";
import { string, object, bool } from "prop-types";
import axios from "axios";
import Constants, { GROUPS } from "../../services/Constants";
import { Button, Menu, MenuItem, Checkbox, Typography } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import HourglassTopIcon from "@mui/icons-material/HourglassTop";
import { refreshIdToken, getGroupsListForUser } from "../../services/Users";
import { USER_GROUPS } from "../../auth/permissions";
import { useMutation } from "react-query";

const UserRoleManager = (props) => {
  const { tableData, userId, enabled, userId: currentUserID = "" } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const [loading, setLoading] = useState(false);
  const [group, setGroup] = useState([]);
  const addGroup = new Set();
  const removeGroup = new Set();
  const { groups = [] } = tableData || {};

  // filtering dev group
  const filterGroups = groups.filter((g) => g !== "ota-dev-group");

  const { mutate: getListOfGroups, isLoading: groupsLoading } = useMutation(
    getGroupsListForUser,
    {
      onSuccess: (data) => {
        setGroup(data);
      },
      onError: (e) => {
        console.log("error", e);
        const { response } = e;
      },
    }
  );

  const handleClick = async (event) => {
    setLoading(true);
    const target = event.currentTarget;
    getListOfGroups(userId);
    setAnchorEl(target);
    setLoading(false);
  };

  const updateUserGroup = (event, selectedGroup) => {
    const checked = event.target.checked;
    const groupIncludes = group.includes(selectedGroup);
    if (checked && !groupIncludes) {
      addGroup.add(selectedGroup);
    } else if (!checked && groupIncludes) {
      removeGroup.add(selectedGroup);
    } else if (!checked && !groupIncludes) {
      addGroup.delete(selectedGroup);
    } else {
      removeGroup.delete(selectedGroup);
    }
  };

  const handleSubmit = async () => {
    setLoading(true);
    // constructing payload
    let addPayload = [...addGroup];
    let removePayload = [...removeGroup];
    addPayload = addPayload.map((e) => {
      return { action: "add", group: e };
    });
    removePayload = removePayload.map((e) => {
      return { action: "remove", group: e };
    });
    const payload = [...addPayload, ...removePayload];

    let url = Constants.ADD_REMOVE_USER_GROUP.replace(":userId", userId);
    if (currentUserID === userId) {
      await axios.patch(url, payload).then(() => {
        handleClose();
        refreshIdToken();
      });
    }
    //need to handle response for notification
    setLoading(false);
  };

  const handleClose = () => {
    setAnchorEl(null);
    addGroup.clear();
    removeGroup.clear();
    setGroup([]);
  };

  const getMenuItems = () => {
    // need state handling in checkbox checked to make it as controlled component
    let menuItems = [];
    if (filterGroups.length > 0) {
      menuItems = filterGroups.map((eachGroup) => (
        <MenuItem key={eachGroup} disableRipple>
          <Checkbox
            defaultChecked={group.includes(eachGroup) ? true : false}
            onChange={(event) => updateUserGroup(event, eachGroup)}
            disableRipple
            disabled={eachGroup === USER_GROUPS.GUEST}
          />
          <span className="role-title">{GROUPS[eachGroup]}</span>
        </MenuItem>
      ));
      menuItems.push(
        <MenuItem key="update-btn" disableRipple>
          <Button
            variant="outlined"
            size="small"
            onClick={() => handleSubmit()}
            endIcon={loading && <HourglassTopIcon />}
            disabled={loading}
          >
            Update
          </Button>
        </MenuItem>
      );
    } else {
      menuItems.push(
        <MenuItem key="loading" disableRipple>
          <Typography>{loading && `Loading...`}</Typography>
          <Typography>
            {loading === false && group.length === 0 && `No data`}
          </Typography>
        </MenuItem>
      );
    }
    return menuItems;
  };

  const open = Boolean(anchorEl);

  return (
    <>
      <Button
        id="basic-button"
        variant="text"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        endIcon={
          loading || groupsLoading ? (
            <HourglassTopIcon />
          ) : (
            <KeyboardArrowDownIcon />
          )
        }
        disabled={groupsLoading || loading || !enabled}
      >
        Member
      </Button>

      {!(groupsLoading || loading) && (
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
          className="role-menu-list"
        >
          {getMenuItems()}
        </Menu>
      )}
    </>
  );
};

UserRoleManager.defaultProps = {
  userId: "",
  enabled: false,
  tableData: {},
};
UserRoleManager.propTypes = {
  userId: string,
  enabled: bool,
  tableData: object,
};

export default UserRoleManager;
