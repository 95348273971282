import React, { lazy, Suspense, useState } from "react";
import { CardActionArea, Dialog, Grid } from "@mui/material";
import { grey } from "@mui/material/colors";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import DottedTile from "../common/DottedTile";
import { ImageUploadSkeleton } from "../common/DialogSkeleton";
import Check from "../../auth/ability";
import { CAN, SUBJECTS } from "../../auth/permissions";
import useModule from "../../hooks/useModule";
import { MODULES } from "../../services/Constants";
const FileUploadDialog = lazy(() => import("./FileUploadDialog"));

const ImageUploadTile = () => {
  const { validateModuleWithMessage } = useModule();
  const [openModal, setOpenModal] = useState(false);
  const handleOpen = () => {
    if (!validateModuleWithMessage(MODULES.OTA)) return;
    setOpenModal(true);
  };
  const handleClose = () => setOpenModal(false);
  return (
    <Check I={CAN.CREATE} a={SUBJECTS.IMAGE}>
      <Grid item xs={6} md={2}>
        <CardActionArea onClick={handleOpen}>
          <DottedTile title={"UPLOAD A NEW IMAGE"}>
            <CloudUploadIcon sx={{ fontSize: "3.5rem", color: grey[500] }} />
          </DottedTile>
        </CardActionArea>
        <Dialog
          open={openModal}
          maxWidth="md"
          fullWidth
          scroll="paper"
          PaperProps={{
            style: {
              maxHeight: "90%",
            },
          }}
        >
          <Suspense fallback={<ImageUploadSkeleton />}>
            {openModal && (
              <FileUploadDialog
                handleClose={handleClose}
                openModal={openModal}
              />
            )}
          </Suspense>
        </Dialog>
      </Grid>
    </Check>
  );
};

export default ImageUploadTile;
