import React, { useContext } from "react";
import { Grid, CardActionArea } from "@mui/material";
import RocketLaunchIcon from "@mui/icons-material/RocketLaunch";
import { grey } from "@mui/material/colors";
import DottedTile from "../common/DottedTile";
import Check from "../../auth/ability";
import { CAN, SUBJECTS } from "../../auth/permissions";
import { WizardContext, ACTIONS } from "../wizard/Context";
import useModule from "../../hooks/useModule";
import { MODULES } from "../../services/Constants";

const CreateDeploymentTile = () => {
  const { validateModuleWithMessage } = useModule();
  const { dispatch } = useContext(WizardContext);
  return (
    <Check I={CAN.CREATE} a={SUBJECTS.DEPLOYMENT}>
      <Grid item xs={12} sm={6} md={2}>
        <CardActionArea
          onClick={() => {
            if (validateModuleWithMessage(MODULES.OTA)) {
              dispatch({
                type: ACTIONS.SHOW_SOFTWARE_WIZARD,
              });
            }
          }}
        >
          <DottedTile title={"CREATE A DEPLOYMENT"}>
            <RocketLaunchIcon sx={{ fontSize: "3.5rem", color: grey[500] }} />
          </DottedTile>
        </CardActionArea>
      </Grid>
    </Check>
  );
};

export default CreateDeploymentTile;
