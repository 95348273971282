import React, { Suspense } from "react";
import { Grid, Card, CardHeader } from "@mui/material";
import { DeploymentListTable } from "../components/deployment";
import "../styles/deployment.css";
import DeploymentStats from "../components/deployment/DeploymentStats";
import StatSkeleton from "../components/common/StatSkeleton";
import CreateDeploymentTile from "../components/deployment/CreateDeploymentTile";

const Deployments = () => {
  return (
    <div className="fragmentContainer">
      <Grid container spacing={2}>
        <Suspense fallback={<StatSkeleton tiles={3} />}>
          <DeploymentStats />
        </Suspense>
        <CreateDeploymentTile />
        <Grid item xs={12} md={12}>
          <Card>
            <CardHeader title="Deployment List"></CardHeader>
            <DeploymentListTable />
          </Card>
        </Grid>
      </Grid>
    </div>
  );
};

export default Deployments;
