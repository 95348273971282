import React, { lazy, Suspense } from "react";
import Check from "../../auth/ability";
import DottedTile from "../../components/common/DottedTile";
import { CAN, SUBJECTS } from "../../auth/permissions";
import { CreateUserSkeleton } from "../../components/common/DialogSkeleton";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import { grey } from "@mui/material/colors";
import { CardActionArea, Dialog, Grid } from "@mui/material";
const CreateUserForm = lazy(() => import("./CreateUserForm"));

const CreateUserTile = () => {
    const [openModal, setOpenModal] = React.useState(false);
    const handleOpen = () => setOpenModal(true);
    const handleClose = () => setOpenModal(false);
    return (
        <>
            <Check I={CAN.CREATE} a={SUBJECTS.RELEASE}>
                <Grid item xs={6} md={2}>
                    <CardActionArea onClick={handleOpen}>
                        <DottedTile title={"CREATE A USER"}>
                            <PersonAddIcon
                                sx={{
                                fontSize: "3.5rem",
                                color: grey[500],
                            }}
                            />
                        </DottedTile>
                    </CardActionArea>  
                </Grid>
            </Check>
            <Dialog
                open={openModal}
                onClose={handleClose}
                fullWidth
                scroll="paper"
                PaperProps={{
                    style: {
                    maxHeight: "90%",
                    },
                }}
                >
                <Suspense fallback={<CreateUserSkeleton/>}>
                    <CreateUserForm onUserCreated={handleClose} />
                </Suspense>
            </Dialog>
        </>
    );
};

export default CreateUserTile;