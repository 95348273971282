import React, { Fragment } from "react";
import { Grid } from "@mui/material";
import { useQuery } from "react-query";
import { getReleasesData } from "../../services/Release";
import InfoTile from "../common/InfoTile";

const ReleaseStats = () => {
  const { data: { data: { releaseCount = 0 } = {} } = {} } = useQuery(
    "getReleasesData",
    getReleasesData,
    {
      refetchOnWindowFocus: false,
      useErrorBoundary: false,
      suspense: true,
    },
  );

  return (
    <Fragment>
      <Grid item xs={6} md={2}>
        <InfoTile title={"Total Releases"} info={releaseCount} />
      </Grid>
    </Fragment>
  );
};

ReleaseStats.defaultProps = {};

ReleaseStats.propTypes = {};

export default ReleaseStats;
