import React, { Fragment } from "react";
import { Grid } from "@mui/material";
import { useQuery } from "react-query";
import { getAllUsersData } from "../../services/Users";
import InfoTile from "../common/InfoTile";

const UserStats = () => {
  const {
    data: users = [],
  } = useQuery("getAllUsersData", getAllUsersData, {
    refetchOnWindowFocus: false,
    useErrorBoundary: false,
    suspense: true,
  });

  return (
    <Fragment>
      <Grid item xs={6} md={2}>
        <InfoTile
          title={"Total No. of Users"}
          info={users?.length}
        />
      </Grid>
    </Fragment>
  );
};

UserStats.defaultProps = {};

UserStats.propTypes = {};

export default UserStats;
