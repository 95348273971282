import React, { Fragment } from "react";
import { Grid } from "@mui/material";
import { useQuery } from "react-query";
import { getStatsForDashboard } from "../../services/Dashboard";
import InfoTile from "../common/InfoTile";

const ConfigurationStats = () => {
    const { data: { configurations = 0 } = {} } = useQuery(
        "getStatsForDashboard",
        getStatsForDashboard,
        {
            refetchOnWindowFocus: false,
            useErrorBoundary: false,
            suspense: true,
        }
    );

    return (
        <Fragment>
            <Grid item xs={6} md={2}>
                <InfoTile title={"Total Configurations"} info={configurations} />
            </Grid>
        </Fragment>
    );
};

ConfigurationStats.defaultProps = {};

ConfigurationStats.propTypes = {};

export default ConfigurationStats;
