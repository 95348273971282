import React, { Fragment, lazy, Suspense } from "react";
import {
  Grid,
  Card,
  CardContent,
  Typography,
  CardActionArea,
  IconButton,
  Tooltip,
  Modal,
  Box,
} from "@mui/material";
import styled from "styled-components";
import { useQuery } from "react-query";
import { Link as RouterLink } from "react-router-dom";
import AnalyticsIcon from '@mui/icons-material/Analytics';
import { grey } from "@mui/material/colors";
import { useTheme } from '@mui/material/styles';
import { getStatsForDashboard } from "../../services/Dashboard";
import dualbg from '../../images/dual-bg.svg';
import { AnalyticsSkeleton } from "../common/DialogSkeleton";
const ExtendedAnalytics = lazy(() => import('./ExtendedAnalytics'));

const DashboardStats = () => {
  const [type, setType] = React.useState("vehicle");
  const [open, setOpen] = React.useState(false);
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 1200,
    bgcolor: 'background.paper',
    p: 4,
  };

  const theme = useTheme();
  const AnalyticsWrapper = styled.div`
    width: 100%;
    position: absolute;
    text-align: end;
    button {
        color: ${theme.custom.dualTheme ? theme.palette.primary[600] : grey[500]}
    }
    `;

  const { data } = useQuery(
    "getStatsForDashboard",
    getStatsForDashboard,
    {
      refetchOnWindowFocus: false,
      useErrorBoundary: false,
      suspense: true,
    }
  );

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Fragment>
      <Grid item xs={12} sm={6} md={2}>
        <CardActionArea LinkComponent={RouterLink} to={`/vehicles`}>
          <Card>
            {theme.custom.dualTheme &&
              <AnalyticsWrapper>
                <Tooltip title="Show Analytics">
                  <IconButton aria-label="analytics" alt
                    onClick={(e) => {
                      e.preventDefault();
                      setType("vehicle");
                      handleOpen();
                    }}>
                    <AnalyticsIcon />
                  </IconButton>
                </Tooltip>
              </AnalyticsWrapper>}
            <CardContent
              {...(theme.custom.dualTheme && { sx: { backgroundImage: `url('${dualbg}')`, backgroundSize: 'cover' } })}
            >
                <Typography color="text.secondary">
                  Vehicles Provisioned
                </Typography>
                <Typography
                  color={theme.palette.primary['A700']}
                  sx={{ fontSize: 60, fontWeight: 300 }}
                >
                  {data?.vehicles ? data.vehicles : "-"}
                </Typography>
            </CardContent>
          </Card>
        </CardActionArea>
      </Grid>
      <Grid item xs={12} sm={6} md={2}>
        <CardActionArea LinkComponent={RouterLink} to={`/images`}>
          <Card>
            {theme.custom.dualTheme &&
              <AnalyticsWrapper>
                <Tooltip title="Show Analytics">
                  <IconButton aria-label="analytics" alt
                    onClick={(e) => {
                      e.preventDefault();
                      setType("image");
                      handleOpen();
                    }}>
                    <AnalyticsIcon />
                  </IconButton>
                </Tooltip>
              </AnalyticsWrapper>}
            <CardContent
              {...(theme.custom.dualTheme && { sx: { backgroundImage: `url('${dualbg}')`, backgroundSize: 'cover' } })}
            >
                <Typography color="text.secondary">Images uploaded</Typography>
                <Typography
                  color={theme.palette.primary['A700']}
                  sx={{ fontSize: 60, fontWeight: 300 }}
                >
                  {data?.images ? data.images : "-"}
                </Typography>
            </CardContent>
          </Card>
        </CardActionArea>
      </Grid>
      <Grid item xs={12} sm={6} md={2}>
        <CardActionArea LinkComponent={RouterLink} to={`/releases`}>
          <Card>
            {theme.custom.dualTheme &&
              <AnalyticsWrapper>
                <Tooltip title="Show Analytics">
                  <IconButton aria-label="analytics" alt
                    onClick={(e) => {
                      e.preventDefault();
                      setType("release");
                      handleOpen();
                    }}>
                    <AnalyticsIcon />
                  </IconButton>
                </Tooltip>
              </AnalyticsWrapper>}
            <CardContent
              {...(theme.custom.dualTheme && { sx: { backgroundImage: `url('${dualbg}')`, backgroundSize: 'cover' } })}
            >
                <Typography color="text.secondary">Releases</Typography>
                <Typography
                  color={theme.palette.primary['A700']}
                  sx={{ fontSize: 60, fontWeight: 300 }}
                >
                  {data?.releases ? data.releases : "-"}
                </Typography>
            </CardContent>
          </Card>
        </CardActionArea>
      </Grid>
      <Grid item xs={6} sm={6} md={2}>
        <CardActionArea LinkComponent={RouterLink} to={`/deployments`}>
          <Card>
            {theme.custom.dualTheme &&
              <AnalyticsWrapper>
                <Tooltip title="Show Analytics">
                  <IconButton aria-label="analytics" alt
                    onClick={(e) => {
                      e.preventDefault();
                      setType("deployment");
                      handleOpen();
                    }}>
                    <AnalyticsIcon />
                  </IconButton>
                </Tooltip>
              </AnalyticsWrapper>}
            <CardContent
              {...(theme.custom.dualTheme && { sx: { backgroundImage: `url('${dualbg}')`, backgroundSize: 'cover' } })}
            >
                <Typography color="text.secondary">Deployments</Typography>
                <Typography
                  color={theme.palette.primary['A700']}
                  sx={{ fontSize: 60, fontWeight: 300 }}
                >
                  {data?.deployments ? data.deployments : "-"}
                </Typography>
            </CardContent>
          </Card>
        </CardActionArea>
      </Grid>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
        width="1200px"
      >
        <Box sx={style}>
          <div style={{
            minWidth: 0,
            height: 400,
            marginBottom: '5%'
          }}>
            <Suspense fallback={<AnalyticsSkeleton/>}>
              <ExtendedAnalytics type={type} color={theme.palette.primary[600]} />
            </Suspense>
          </div>
        </Box>
      </Modal>
    </Fragment>
  );
};

DashboardStats.defaultProps = {};

DashboardStats.propTypes = {};

export default DashboardStats;
