import React, { useEffect, useState } from "react";
import {
  TextField,
  Checkbox,
  Pagination,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Card,
  CardHeader,
  Divider,
  CardContent,
  CardActions,
  Tooltip,
} from "@mui/material";
import { SearchOutlined } from "@mui/icons-material";
import DisabledByDefaultRoundedIcon from "@mui/icons-material/DisabledByDefaultRounded";

const VehiclesInGroupList = (props) => {
  const {
    isUpdate,
    isPrimaryList,
    isSearchable,
    title,
    items: vehicles,
    meta,
    selectedVehicles = [],
    setSelectedVehicles,
    removedVehicles = [],
    setRemovedVehicles,
    selected,
    setSelected,
    onSearchInput,
    paginationData,
    setPaginationData,
    handlePageChange,
    vehiclesInGroupList = [],
  } = props;

  const [searchText, setSearchText] = useState("");
  const [items, setItems] = useState([]);
  const [pagination, setPagination] = useState({
    ...paginationData,
    page: 1,
  });

  const updateItems = (_, pageNumber) => {
    setPagination({
      ...pagination,
      page: pageNumber,
    });
    const startIndex = (pageNumber - 1) * 50;
    const endIndex = pageNumber * 50;
    setItems(vehicles?.slice(startIndex, endIndex));
  };

  const handleToggleAll = (i) => {
    const {
      target: { checked },
    } = i;
    if (isPrimaryList) {
      if (checked) {
        const allItemsNoInGroupAlready = items.filter((i) => {
          const isVehicleAlreadyInGroup = selectedVehicles?.filter(
            (v) => v.vehicleKey === i.vehicleKey
          );
          return !isVehicleAlreadyInGroup?.length;
        });
        setSelected([...allItemsNoInGroupAlready]);
      } else {
        setSelected([]);
      }
    } else {
      if (checked) {
        setSelected([
          ...selectedVehicles,
          ...items?.filter(
            (i) =>
              !removedVehicles?.filter((j) => j.vehicleKey === i.vehicleKey)
                ?.length
          ),
        ]);
      } else {
        setSelected([]);
      }
    }
  };

  const numberOfChecked = (i) => {
    return 0;
  };

  const handleToggle = (vehicle) => {
    if (vehicle?.vehicleKey) {
      const itemAlreadyPresent = selected?.filter(
        (i) => i?.vehicleKey === vehicle.vehicleKey
      )?.length;
      if (itemAlreadyPresent) {
        const otherItems = selected?.filter(
          (i) => i?.vehicleKey !== vehicle.vehicleKey
        );
        setSelected([...otherItems]);
      } else {
        setSelected([...selected, vehicle]);
      }
    }
  };

  const onSearchInputLocal = (text) => {
    if (!!text) {
      setSearchText(text);
    } else {
      setSearchText("");
    }
  };

  const handlePageChangeLocal = () => {
    // TODO
  };

  const isDisabled = (value) => {
    const isInRemoved = removedVehicles?.filter(
      (r) => r.vehicleKey === value.vehicleKey
    )?.length;
    return isInRemoved;
  };

  const getItemstoRenderWithFilter = (checkedItems, listItems) => {
    if (!!searchText) {
      return [
        ...checkedItems.filter((v) => v?.serialNo.indexOf(searchText) > -1),
        ...listItems,
      ];
    } else {
      return [...checkedItems, ...listItems];
    }
  };

  const itemsAvailableForSelection = () => {
    return [
      ...selectedVehicles,
      ...items?.filter(
        (i) =>
          !removedVehicles?.filter((j) => j.vehicleKey === i.vehicleKey)?.length
      ),
    ];
  };

  useEffect(() => {
    setItems(vehicles?.slice(0, 50));
    setPagination({
      ...paginationData,
      page: 1,
    });
  }, [vehicles, paginationData]);

  return (
    <Card>
      <CardHeader
        sx={{ px: 2, py: 1, pb: 0 }}
        avatar={
          <Checkbox
            onClick={handleToggleAll}
            checked={
              selected?.length &&
              selected?.length === itemsAvailableForSelection()?.length
            }
            indeterminate={
              selected?.length &&
              selected?.length < itemsAvailableForSelection()?.length
            }
            disabled={items.length === 0}
            inputProps={{
              "aria-label": "all items selected",
            }}
          />
        }
        title={title}
        subheader={`${selected?.length}/${
          itemsAvailableForSelection()?.length
        } selected`}
      />
      <CardHeader
        sx={{ px: 2, py: 1, pt: 0 }}
        avatar={
          isSearchable ? (
            <SearchOutlined style={{ width: "42px" }} />
          ) : (
            <div style={{ width: "42px", height: "26px" }} />
          )
        }
        subheader={
          isSearchable ? (
            <TextField
              placeholder="Search"
              size="small"
              variant="standard"
              onChange={(e) => {
                const {
                  target: { value },
                } = e;
                if (meta) {
                  onSearchInput(value);
                }
                onSearchInputLocal(value);
              }}
            />
          ) : null
        }
      />
      <Divider />
      <CardContent>
        <List
          sx={{
            width: "auto",
            bgcolor: "background.paper",
            overflow: "auto",
          }}
          style={{
            height: isUpdate ? "calc(100vh - 586px)" : "calc(100vh - 612px)",
          }}
          dense
          component="div"
          role="list"
        >
          {getItemstoRenderWithFilter(selectedVehicles, items)?.map((value) => {
            const labelId = `transfer-list-all-item-${value?.serialNo}-label`;

            return (
              <ListItem
                key={value?.serialNo}
                role="listitem"
                style={{ paddingLeft: "0px" }}
              >
                <ListItemIcon>
                  <>
                    {isDisabled(value) ? (
                      <Tooltip
                        title={`${value?.serialNo} is removed from list.`}
                      >
                        <DisabledByDefaultRoundedIcon
                          style={{ margin: "9px" }}
                          color="disabled"
                        />
                      </Tooltip>
                    ) : (
                      <Checkbox
                        checked={selected.indexOf(value) !== -1}
                        tabIndex={-1}
                        disableRipple
                        inputProps={{
                          "aria-labelledby": labelId,
                        }}
                        onClick={() => handleToggle(value)}
                      />
                    )}
                  </>
                </ListItemIcon>
                <Tooltip title={value?.serialNo} placement="bottom-start">
                  <ListItemText
                    style={{ whiteSpace: "nowrap" }}
                    id={value?.serialNo}
                    primary={value?.serialNo}
                  />
                </Tooltip>
              </ListItem>
            );
          })}

          <ListItem />
        </List>
      </CardContent>
      <Divider />
      <CardActions>
        <Pagination
          sx={{ mt: "6px" }}
          onChange={updateItems}
          {...pagination}
          color="primary"
        />
      </CardActions>
    </Card>
  );
};

export default VehiclesInGroupList;
