import React from "react";
import { Grid } from "@mui/material";
import { getStatsForVehicleGroupsView } from "../../services/vehicle";
import { useQuery } from "react-query";
import InfoTile from "../common/InfoTile";

const VehicleGroupsStats = () => {

  const { data } = useQuery(
    "getStatsForVehicleGroupsView",
    getStatsForVehicleGroupsView,
    {
      refetchOnWindowFocus: false,
      useErrorBoundary: false,
      suspense: true,
    }
  );
  return (
    <>
      <Grid item xs={6} md={2}>
        <InfoTile
          title={"Total Vehicle Groups"}
          info={data?.totalGroups || "0"}
        />
      </Grid>
    </>
  );
};

export default VehicleGroupsStats;
