import React, { useContext } from "react";
import { Grid, CardActionArea } from "@mui/material";
import TuneIcon from "@mui/icons-material/Tune";
import DottedTile from "../common/DottedTile";
import { grey } from "@mui/material/colors";
import Check from "../../auth/ability";
import { CAN, SUBJECTS } from "../../auth/permissions";
import { ACTIONS, WizardContext } from "../wizard/Context";
import { MODULES } from "../../services/Constants";
import useModule from "../../hooks/useModule";

const CreateConfigurationTile = () => {
  const { dispatch } = useContext(WizardContext);
  const { validateModuleWithMessage } = useModule();
  return (
    <Check I={CAN.CREATE} a={SUBJECTS.CONFIGURATION}>
      <Grid item xs={12} sm={6} md={2}>
        <CardActionArea
          onClick={() => {
            if (validateModuleWithMessage(MODULES.CONFIGURATION)) {
              dispatch({
                type: ACTIONS.SHOW_CONFIG_WIZARD,
              });
            }
          }}
        >
          <DottedTile title={"CREATE A CONFIGURATION"}>
            <TuneIcon sx={{ fontSize: "3.5rem", color: grey[500] }} />
          </DottedTile>
        </CardActionArea>
      </Grid>
    </Check>
  );
};

export default CreateConfigurationTile;
