import React, { lazy, Suspense } from "react";
import { Dialog, Grid, CardActionArea } from "@mui/material";
import { grey } from "@mui/material/colors";
import RssFeedIcon from "@mui/icons-material/RssFeed";
import DottedTile from "../common/DottedTile";
import Check from "../../auth/ability";
import { CAN, SUBJECTS } from "../../auth/permissions";
import { ReleaseSkeleton } from "../common/DialogSkeleton";
import useModule from "../../hooks/useModule";
import { MODULES } from "../../services/Constants";
const CreateReleasePopup = lazy(() => import("./CreateReleasePopup"));

const CreateReleaseTile = () => {
  const { validateModuleWithMessage } = useModule();
  const [openModal, setOpenModal] = React.useState(false);
  const handleOpen = () => {
    if (!validateModuleWithMessage(MODULES.OTA)) return;
    setOpenModal(true);
  };
  const handleClose = () => setOpenModal(false);

  return (
    <Check I={CAN.CREATE} a={SUBJECTS.RELEASE}>
      <Grid item xs={6} md={2}>
        <CardActionArea onClick={handleOpen}>
          <DottedTile title={"CREATE A RELEASE"}>
            <RssFeedIcon sx={{ fontSize: "3.5rem", color: grey[500] }} />
          </DottedTile>
        </CardActionArea>
        <Dialog
          open={openModal}
          onClose={handleClose}
          maxWidth="md"
          fullWidth
          scroll="paper"
          PaperProps={{
            style: {
              maxHeight: "90%",
            },
          }}
        >
          <Suspense fallback={<ReleaseSkeleton />}>
            <CreateReleasePopup onReleaseCreated={handleClose} />
          </Suspense>
        </Dialog>
      </Grid>
    </Check>
  );
};

export default CreateReleaseTile;
