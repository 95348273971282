import React, { Suspense } from "react";
import { Card, CardHeader } from "@mui/material";
import UserList from "../components/user/UserList";
import Grid from "@mui/material/Grid";
import "../styles/user.css";
import { Route, Routes } from "react-router-dom";
import User from "../routes/User";
import UserStats from "../components/user/UserStats";
import StatSkeleton from "../components/common/StatSkeleton";
import CreateUserTile from "../components/user/CreateUserTile";

const Users = (props) => {
  return (
    <Routes>
      <Route
        path="/"
        exact
        element={
          <div className="fragmentContainer">
            <Grid container spacing={2}>
              <Suspense fallback={<StatSkeleton />}>
                <UserStats />
              </Suspense>
              <CreateUserTile />
              <Grid item xs={12} md={12} lg={12}>
                <Card>
                  <CardHeader title="Users"></CardHeader>
                  <UserList {...props}/>
                </Card>
              </Grid>
            </Grid>
          </div>
        }
      />
      <Route path="/:userId/*" element={<User />} />
    </Routes>
  );
};

export default Users;
