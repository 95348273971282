import axios from "axios";
import Constants from "../services/Constants";
import { constructQueryUrl } from "./Utils";

export const createImage = async (payload) => {
  return axios.post(Constants.PUBLISH_IMAGE_METADATA, payload);
};

export const updateImageUploadStatus = async (payload) => {
  return axios.put(Constants.PUBLISH_IMAGE_METADATA_UPLOAD_STATUS, payload);
};

export const getAllCompletedImages = async () => {
  //Get only Images with upload status completed
  const queryStr =
    'filter={"items":[{"columnField":"uploadStatus","operatorValue":"equals","value":"Completed"}]}';
  return await axios.get(Constants.LIST_IMAGES + `?${queryStr}`);
};

export const getStsToken = async () => {
  return await axios.get(Constants.STS_TOKEN);
};

export const getImagesData = async () => {
  let query = Constants.IMAGE_REPORT;
  return await axios.get(query);
};

export const getServerConfiguration = async () => {
  return await axios.get(Constants.SERVER_CONFIG);
};

export const getImagesList = async ({ queryKey }) => {
  const [
    _,
    page,
    pageSize,
    field,
    sort,
    filterValue,
    columns,
    extraFilters,
    fetchAll,
  ] = queryKey;

  let query =
    Constants.LIST_IMAGES +
    `?custid=${
      localStorage?.selectedCustomer
    }&page=${page}&take=${pageSize}&sortby=${field}&order=${sort.toUpperCase()}`;

  if (fetchAll) {
    query = `${query}&fetchAll=true`;
  }

  query = constructQueryUrl({ filterValue, query, columns });
  return await axios.get(query);
};

export const getImageByName = async ({ queryKey }) => {
  const url = Constants.DESCRIBE_IMAGE_BY_FILE_NAME.replace(
    ":filename",
    encodeURI(queryKey?.[1]?.name)
  );
  const { data } = await axios.get(url);
  return data;
};

export const getImageById = async ({ queryKey }) => {
  const [_, id] = queryKey;
  const url = Constants.DESCRIBE_IMAGE.replace(":id", id);
  const { data } = await axios.get(url);
  return data;
};
