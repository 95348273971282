import React, { Suspense } from "react";
import { Card, CardContent, Grid } from "@mui/material";
import { ImageStats, ImageUploadDataGrid } from "../components/image";
import DataGridTitle from "../components/common/DataGridTitle";
import StatSkeleton from "../components/common/StatSkeleton";
import ImageUploadTile from "../components/image/ImageUploadTile";

const ImageRepofragment = () => {
  return (
    <div className="fragmentContainer">
      <Grid container spacing={2}>
        <Suspense fallback={<StatSkeleton tiles={2}/>}>
          <ImageStats />
        </Suspense>
        <ImageUploadTile />
        <Grid item xs={12} md={12}>
          <Card>
            <DataGridTitle title="Available Images" />
            <CardContent sx={{
              padding: 0, ":last-child": {
                padding: 0
              }
            }}>
              <ImageUploadDataGrid />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
};

export default ImageRepofragment;
