import React, { useState, lazy, Suspense } from "react";
import { Grid, CardActionArea, Dialog } from "@mui/material";
import Check from "../../../auth/ability";
import { CAN, SUBJECTS } from "../../../auth/permissions";
import { grey } from "@mui/material/colors";
import DottedTile from "../../common/DottedTile";
import SpokeIcon from "@mui/icons-material/Spoke";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { CreateVehicleGroupCSVSkeleton, CreateVehicleGroupSkeleton } from "../../common/DialogSkeleton";
const CreateVehicleGroup = lazy(() => import("./CreateVehicleGroup"));
const CreateVehicleGroupFromCsv = lazy(() => import("./CreateVehicleGroupFromCsv"));

const CreateVehicleGroupTile = () => {
  const [openModal, setOpenModal] = useState(false);
  const handleOpen = () => setOpenModal(true);
  const [openModalCsv, setOpenModalCsv] = useState(false);
  const handleOpenCsv = () => setOpenModalCsv(true);
    
  return (
    <>
      <Check I={CAN.CREATE} a={SUBJECTS.GROUP}>
        <Grid item xs={6} md={2}>
          <CardActionArea onClick={handleOpen}>
            <DottedTile title={"CREATE A GROUP"}>
              <SpokeIcon sx={{ fontSize: "3.5rem", color: grey[500] }} />
            </DottedTile>
          </CardActionArea>
        </Grid>
        <Grid item xs={6} md={2}>
          <CardActionArea onClick={handleOpenCsv}>
            <DottedTile title={"CREATE A GROUP FROM CSV"}>
              <CloudUploadIcon sx={{ fontSize: "3.5rem", color: grey[500] }} />
            </DottedTile>
          </CardActionArea>
        </Grid>
      </Check>
      <Grid item xs={3}></Grid>
      {openModal && (
        <Dialog
          open={openModal}
          onClose={() => setOpenModal(!openModal)}
          PaperProps={{
            sx: {
              width: "50%",
              maxWidth: "1200px",
              height: "calc(100vh - 30px)",
            },
          }}
        >
          <Suspense fallback={<CreateVehicleGroupSkeleton />}>
            <CreateVehicleGroup
              open={openModal}
              toggleDialog={() => setOpenModal(!openModal)}
            />
          </Suspense>
        </Dialog>
      )}
      {openModalCsv && (
        <Dialog
          open={openModalCsv}
          onClose={() => setOpenModalCsv(!openModalCsv)}
          PaperProps={{
            sx: {
              width: "55%",
              maxWidth: "1200px",
              maxHeight: "700px",
            },
          }}
        >
          <Suspense fallback={<CreateVehicleGroupCSVSkeleton />}>
            <CreateVehicleGroupFromCsv
              open={openModalCsv}
              toggleDialog={() => setOpenModalCsv(!openModalCsv)}
            />
          </Suspense>
        </Dialog>
      )}
    </>
  );
};

export default CreateVehicleGroupTile;
