import React, { Fragment } from "react";
import { Grid } from "@mui/material";
import { useQuery } from "react-query";
import { getImagesData } from "../../services/Image";
import InfoTile from "../common/InfoTile";

const ImageStats = () => {
  const { data: { data: { imageCount = 0, totalBytes = "0" } = {} } = {} } =
    useQuery("getImagesData", getImagesData, {
      refetchOnWindowFocus: false,
      useErrorBoundary: false,
      suspense: true,
    });

  return (
    <Fragment>
      <Grid item xs={6} md={2}>
        <InfoTile title={"Total Uploaded Images"} info={imageCount} />
      </Grid>
      <Grid item xs={6} md={2}>
        <InfoTile title={"Total Uploaded Bytes"} info={totalBytes} />
      </Grid>
    </Fragment>
  );
};

ImageStats.defaultProps = {};

ImageStats.propTypes = {};

export default ImageStats;
