import React from "react";
import { Grid, Card, CardContent, Typography } from "@mui/material";
import { getStatsForVehiclesView } from "../../services/vehicle";
import { useTheme } from '@mui/material/styles';
import { useQuery } from "react-query";
import InfoTile from "../common/InfoTile";

const VehicleStats = () => {
  const theme = useTheme();
  const { data } = useQuery(
    "getStatsForVehiclesView",
    getStatsForVehiclesView,
    {
      refetchOnWindowFocus: false,
      useErrorBoundary: false,
      suspense: true,
    }
  );
  return (
    <>
      <Grid item xs={6} md={3}>
        <InfoTile
          title={"Visteon Provisioned"}
          info={data?.visteonProvisioned}
        />
      </Grid>
      <Grid item xs={6} md={3}>
        <InfoTile
          title={"Customer Provisioned"}
          info={data?.customerProvisioned}
        />
      </Grid>
      <Grid item xs={6} md={4}>
        <Card>
          <Grid container >
            <Grid item xs={6}>
              <CardContent className="tile-cards">
                <Typography color="text.secondary">
                  Active vehicles
                </Typography>
                <Typography
                  className="tile-number"
                  color={theme.palette.primary['A700']}
                  sx={{ fontSize: 30, fontWeight: 400 }}
                >
                  {data?.activeVehicles}
                </Typography>
              </CardContent>
            </Grid>
            <Grid item xs={6}>
              <CardContent className="tile-cards">
                <Typography color="text.secondary">
                  Inactive vehicles
                </Typography>
                <Typography
                  className="tile-number"
                  color={theme.palette.primary['A700']}
                  sx={{ fontSize: 30, fontWeight: 400 }}
                >
                  {data?.inActiveVehicles}
                </Typography>
              </CardContent>
            </Grid>
          </Grid>
        </Card>
      </Grid>
      <Grid item xs={6} md={2}>
        <InfoTile
          title={"Subscriptions(Vehicles)"}
          info={data?.vehiclesWithActiveSubscriptions}
        />
      </Grid>
    </>
  );
};

export default VehicleStats;
