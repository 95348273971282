import React, { Suspense } from "react";
import { Grid, Card, CardHeader } from "@mui/material";
import { ConfigurationList } from "../components/configuration";
import "../styles/deployment.css";
import ConfigurationStats from "../components/configuration/ConfigurationStats";
import StatSkeleton from "../components/common/StatSkeleton";
import CreateConfigurationTile from "../components/configuration/CreateConfigurationTile";

const Configurations = () => {
    return (
        <div className="fragmentContainer">
            <Grid container spacing={2}>
                <Suspense fallback={<StatSkeleton tiles={1}/>}>
                    <ConfigurationStats />
                </Suspense>
                <CreateConfigurationTile />
                <Grid item xs={12} md={12}>
                    <Card>
                        <CardHeader title="Custom Configuration List"></CardHeader>
                        <ConfigurationList />
                    </Card>
                </Grid>
            </Grid>
        </div>
    );
};

export default Configurations;
