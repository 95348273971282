import React, {
    useContext,
    useState,
    useEffect,
    lazy,
    Suspense,
} from "react";
import {
    Dialog,
} from "@mui/material";
import { useMutation, useQueryClient } from "react-query";
import { publishDeployment } from "../../services/deployment";
import { publishConfiguration } from "../../services/configuration";
import { WizardContext, ACTIONS } from "./Context";
import { useToast } from "../toast";
import useModule from "../../hooks/useModule";
import { MODULES } from "../../services/Constants";
import { ChooseDeploymentSkeleton, CreateDeploymentSkeleton } from "../common/DialogSkeleton";
const DialogContainer = lazy(() => import("./WizardBlocks").then(module => ({ default: module.DialogContainer })));
const DialogFooter = lazy(() => import("./WizardBlocks").then(module => ({ default: module.DialogFooter })));
const DialogHeader = lazy(() => import("./WizardBlocks").then(module => ({ default: module.DialogHeader })));

const WorkflowWizard = () => {
    const { validateModuleWithMessage } = useModule();
    const { dispatch, state } = useContext(WizardContext);
    const { addToast } = useToast();
    const [hidePublish, setHidePublish] = useState(false);
    const [steps, setSteps] = useState([]);

    const {
        showWizard = false,
        activeStep = 0,
        wizardType = '',
        launch = false,
        deploymentId,
        configId
    } = state;

    useEffect(() => {
        if (wizardType === 'software') {
            setSteps([
                "Select vehicles or vehicle groups",
                "Select release",
                "Create a deployment",
                "Review and create",
            ]);
        } else if (wizardType === 'configuration') {
            setSteps([
                "Select vehicles or vehicle groups",
                "Create configuration",
                "Update config state",
                "Review and create",
            ])
        } else {
            setSteps([]);
        }
    }, [wizardType]);

    // Access the client
    const queryClient = useQueryClient();

    // Mutations
    const { mutate: publishSoftware, isLoading: softwareLoading } = useMutation(publishDeployment, {
        onSuccess: () => {
            dispatch({
                type: ACTIONS.DEPLOYMENT_MESSAGE,
                payload: {
                    deploymentMessage: "Software Deployment Published",
                },
            });
            setHidePublish(true);
            addToast({
                type: "success",
                message: "Software Deployment Published",
                autoClose: 5000,
            });
        },
        onError: (err) => {
            dispatch({
                type: ACTIONS.DEPLOYMENT_MESSAGE,
                payload: {
                    deploymentMessage: "Software Publish Failed",
                },
            });
            addToast({
                type: "error",
                message: err.response.data.message || "Software Publish Failed",
                autoClose: 5000,
            });
        },
        onSettled: () => {
            // Invalidate and refetch
            queryClient.invalidateQueries("deployments");
            queryClient.invalidateQueries("getStatsForDashboard");
        },
    });

    const { mutate: publishConfig, isLoading: configLoading } = useMutation(publishConfiguration, {
        onSuccess: () => {
            setHidePublish(true);
            dispatch({
                type: ACTIONS.DEPLOYMENT_MESSAGE,
                payload: {
                    deploymentMessage: "Configuration Deployment Published",
                },
            });
            addToast({
                type: "success",
                message: "Configuration Deployment Published",
                autoClose: 5000,
            });
        },
        onError: (err) => {
            dispatch({
                type: ACTIONS.DEPLOYMENT_MESSAGE,
                payload: {
                    deploymentMessage: "Configuration Publish Failed",
                },
            });
            addToast({
                type: "error",
                message: err.response.data.message || "Configuration Publish Failed",
                autoClose: 5000,
            });
        },
        onSettled: () => {
            // Invalidate and refetch
            queryClient.invalidateQueries("getStatsForDashboard");
            queryClient.invalidateQueries("configurations");
        },
    });

    const handleClose = (_, reason) => {
        if (reason !== "backdropClick") {
            dispatch({
                type: ACTIONS.RESET,
            });
        }
    };

    const typeListener = (event) => {
        const type = event.target.getAttribute("data-name");
        switch (type) {
            case 'software':
                if (validateModuleWithMessage(MODULES.OTA)) {
                    dispatch({
                        type: ACTIONS.SHOW_SOFTWARE_WIZARD
                    });
                }
                break;

            case 'configuration':
                if (validateModuleWithMessage(MODULES.CONFIGURATION)) {
                    dispatch({
                        type: ACTIONS.SHOW_CONFIG_WIZARD
                    });
                }
                break;

            default:
                dispatch({
                    type: ACTIONS.RESET
                });
                break;
        }
    }

    const handleNext = () => {
        if (activeStep + 1 === steps.length && !launch) {
            dispatch({
                type: ACTIONS.UPDATE_STEP,
                payload: {
                    launch: true,
                },
            });
            return;
        }
        if (activeStep + 1 === steps.length && launch) {
            handleClose();
            return;
        }
        dispatch({
            type: ACTIONS.UPDATE_STEP,
            payload: {
                activeStep: activeStep + 1,
                disableNextBtn: activeStep + 2 === steps.length ? false : true,
            },
        });
    };

    const handleBack = () => {
        dispatch({
            type: ACTIONS.UPDATE_STEP,
            payload: {
                activeStep: activeStep - 1,
                disableNextBtn: false,
            },
        });
    };

    const getBtnText = () => {
        if (activeStep === steps.length - 1 && launch) return "Close";
        if (activeStep === steps.length - 1) return "Create";
        return "Next";
    };

    const handlePublish = () => {
        if (wizardType === 'software') {
            publishSoftware(deploymentId);
        } else if (wizardType === 'configuration') {
            publishConfig(configId);
        }
    }

    return (
        <Dialog
            open={showWizard}
            onClose={handleClose}
            fullWidth
            scroll="paper"
            PaperProps={{
                style: {
                    height: "95vh",
                    minWidth: "110vh"
                },
            }}
        >
            <Suspense fallback={wizardType === '' ? <ChooseDeploymentSkeleton/> : <CreateDeploymentSkeleton/>}>
                <DialogHeader
                    handleClose={handleClose}
                    wizardType={wizardType}
                />
                <DialogContainer
                    {...state}
                    steps={steps}
                    typeListener={typeListener}
                />
                <DialogFooter
                    {...state}
                    steps={steps}
                    handleBack={handleBack}
                    hidePublish={hidePublish}
                    handleNext={handleNext}
                    getBtnText={getBtnText}
                    handlePublish={handlePublish}
                    isLoading={configLoading || softwareLoading}
                />
            </Suspense>
        </Dialog >
    );
};

export default WorkflowWizard;
