import React, { useContext } from 'react';
import { useQuery } from "react-query";
import { DownloadCSV } from '../../services/Utils';
import { WizardContext, ACTIONS } from "../wizard/Context";
import { ungzip } from 'pako';

const ExportBackgroundDownload = () => {
    const { dispatch, state } = useContext(WizardContext);
    const {
        initiateDownload,
        downloadData: {
            data = {},
            endpointFn = () => { },
            queryName = ''
        } = {}
    } = state || {};

    //Queries
    useQuery(
        data,
        endpointFn,
        {
            onSuccess: (response) => {
                const decompressedResp = ungzip(response?.data?.data || [], { to: 'string'});
                DownloadCSV(decompressedResp, queryName);
            },
            onSettled: () => {
                dispatch({
                    type: ACTIONS.EXPORT_DOWNLOAD,
                    payload: {
                        initiateDownload: false,
                    }
                })
            },
            enabled: initiateDownload,
            retry: 0,
            refetchOnWindowFocus: false
        }
    );

    return null;
}

export default ExportBackgroundDownload;