import React, { useState, lazy, Suspense } from "react";
import { Button } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { Dialog } from "@mui/material";
import Check from "../../auth/ability";
import { CAN, SUBJECTS } from "../../auth/permissions";
import { useToast } from "../toast";
import { IMAGE_UPLOAD_ERRORS } from "../../services/Constants";
import { ImageUploadSkeleton } from "../common/DialogSkeleton";
const FileUploadDialog = lazy(() => import("./FileUploadDialog"));

const ImageUploadDataTableRowActions = ({ data }) => {
  const {
    row: { uploadStatus },
  } = data || {};
  const [openModal, setOpenModal] = useState(false);
  const [resumeUpload, setResumeUpload] = useState(null);
  const [resumeUpdloadStep, setResumeUpdloadStep] = useState("0");
  const { addToast } = useToast();

  const handleResumeUpload = (id) => {
    if (window.navigator.onLine) {
      if (!!id) {
        openReuploadDialog(id);
      } else {
        setOpenModal(true);
      }
    } else {
      addToast({
        type: "error",
        message: IMAGE_UPLOAD_ERRORS.ERR_NETWORK_ISSUE,
        autoClose: 3000,
      });
    }
  };

  const handleClose = () => {
    resetValues();
  };

  const resetValues = () => {
    setResumeUpload(null);
    setResumeUpdloadStep("0");
    setOpenModal(false);
  };
  const openReuploadDialog = (id) => {
    setResumeUpdloadStep("2");
    setResumeUpload(id);
    setOpenModal(true);
  };

  return (
    <>
      <div>
        <Button size="small" component={RouterLink} to={`/Images/${data.id}`}>
          Details
        </Button>
        {!(uploadStatus === "Failed" || uploadStatus === "Completed") && (
          <>
            <Check I={CAN.MANAGE} a={SUBJECTS.IMAGE}>
              {<span>|</span>}
              <Button size="small" onClick={() => handleResumeUpload(data?.id)}>
                Upload
              </Button>
            </Check>
          </>
        )}
      </div>
      <Dialog
        open={openModal}
        maxWidth="md"
        fullWidth
        scroll="paper"
        PaperProps={{
          style: {
          maxHeight: "90%",
          },
        }}
      >
        <Suspense fallback={<ImageUploadSkeleton/>}>
          {openModal && <FileUploadDialog handleClose={handleClose} openModal={openModal} resumeUpload={resumeUpload} resumeUpdloadStep={resumeUpdloadStep}/>}
        </Suspense>
      </Dialog>
    </>
  );
};
export default ImageUploadDataTableRowActions;
