import { Card, CardContent, Grid, Skeleton } from "@mui/material";
import styled from "styled-components";

const SkeletonWrapper = styled.div`
  margin-top:5%;
`;

const CardSkeleton = ({ height, repeat }) => {
    return (
        <Card>
            <Grid container >
                {Array.from({ length: repeat }).map((_, index) => (
                    <Grid item xs={6} key={index}>
                        <CardContent className="tile-cards">
                            <Skeleton
                                animation="wave"
                                variant="text"
                                sx={{ fontSize: 14 }}
                                width={200}
                                height={25}
                            />
                            <SkeletonWrapper>
                                <Skeleton
                                    animation="wave"
                                    variant="rounded"
                                    width={150}
                                    height={height}
                                />
                            </SkeletonWrapper>
                        </CardContent>
                    </Grid>
                ))}
            </Grid>
        </Card>
    );
};

const StatSkeleton = ({ tiles=1, width=2, height=44, repeat=1 }) => {
    return (
        <>
            {Array.from({ length: tiles }).map((_, index) => (
                <Grid item xs={6} md={width} key={index}>
                    <CardSkeleton height={height} repeat={repeat}/>
                </Grid>
            ))}
        </>
    );
};

export default StatSkeleton;