import { Box, Skeleton } from "@mui/material";

export const AnalyticsSkeleton = () => {
    return (
        <Box>
            <Box display={"flex"} justifyContent={"center"}>
                <Skeleton variant="text" width={200} height={40} />
            </Box>
            <Skeleton sx={{ borderRadius: 1 }} variant="rect" height={400} />
        </Box>
    );
};

export const ReleaseSkeleton = () => {
    return (
        <Box sx={{ mx: 3, mt: 2, mb: 1 }}>
            <Box>
                <Skeleton variant="text" width={200} height={40} />
                <Skeleton variant="text" height={52} />
            </Box>
            <Skeleton sx={{ mt: 4, mb: 3, borderRadius: 1 }} variant="rect" height={"60vh"} />
            <Skeleton variant="text" width={150} height={60} />
        </Box>
    );
};

export const ImageUploadSkeleton = () => {
    return (
        <Box sx={{ mx: 2, my: 1 }}>
            <Skeleton variant="text" width={150} height={55} />
            <Box display={"flex"} justifyContent={"center"}>
                <Skeleton variant="text" width={300} height={80} />
            </Box>
            <Box display={"flex"} justifyContent={"flex-end"}>
                <Skeleton variant="text" width={80} height={50} />
            </Box>
        </Box>
    );
};

const FormElementSkeleton = () => {
    return (
        <>
            <Skeleton variant="text" width={80} height={40} />
            <Skeleton variant="rect" sx={{ borderRadius: 1 }} height={50} />
        </>
    );
};

export const CreateUserSkeleton = () => {
    return (
        <Box sx={{ mx: 3, my: 1 }}>
            <Skeleton variant="text" width={150} height={40} />
            <Box sx={{ mt: 5 }}>
                <Skeleton variant="text" width={450} height={40} />
                <FormElementSkeleton/>
                <FormElementSkeleton/>
                <FormElementSkeleton/>
                <Skeleton variant="text" sx={{ borderRadius: 1 }} height={80} />
            </Box>
            <Box sx={{ mb: 3 }}>
                <Skeleton sx={{ borderRadius: 1 }} variant="rect" height={260} />
            </Box>
            <Skeleton variant="text" height={70} />
        </Box>
    );
};

export const CreateDeploymentSkeleton = () => {
    return (
        <Box sx={{ mx: 3, mt: 2 }} display={"flex"} flexDirection={"column"} justifyContent={"space-between"} height={"100%"}>
            <Skeleton variant="text" width={300} height={60} />
            <Skeleton variant="text" height={150} />
            <Skeleton sx={{ m: "auto" }} variant="text" width={350} height={100} />
            <Box sx={{ mx: 3, my: 10 }} height={"100%"}>
                <Box display={"flex"} justifyContent={"flex-end"}>
                    <Skeleton variant="text" width={"35%"} height={80} />
                </Box>
                <Skeleton sx={{ borderRadius: 2 }} variant="rect" height={"95%"} />
            </Box>
            <Box sx={{ mx: -2 }} display="flex" justifyContent="space-between">
                <Skeleton variant="text" width={70} height={60} />
                <Skeleton variant="text" width={70} height={60} />
            </Box>
        </Box>
    );
};

export const ChooseDeploymentSkeleton = () => {
    return (
        <Box sx={{ mx: 3, my: 2 }} height={"90%"}>
            <Skeleton variant="text" width={300} height={40} />
            <Box sx={{ mt: 1 }} display="flex" alignItems="center" justifyContent="space-around" height={"100%"}>
                <Skeleton sx={{ borderRadius: 1 }} variant="rect" width="40%" height={220} />
                <Skeleton sx={{ borderRadius: 1 }} variant="rect" width="40%" height={220} />
            </Box>
        </Box>
    );
};

export const CreateVehicleGroupSkeleton = () => {
    return (
        <Box sx={{ mx: 3, my: 2 }} display={"flex"} justifyContent={"space-between"} flexDirection={"column"} height={"100%"}>
            <Skeleton variant="text" width={300} height={40} />
            <Box>
                <Skeleton variant="text" width={400} height={80} />
                <Skeleton variant="text" width={500} height={80} />
                <Skeleton variant="text" height={80} />
            </Box>
            <Box sx={{ my: 2 }} display="flex" justifyContent="space-between" height={"100%"}>
                <Skeleton sx={{ borderRadius: 1 }} variant="rect" width={"41%"} height={"100%"}/>
                <Skeleton sx={{ borderRadius: 1 }} variant="rect" width={"41%"} height={"100%"}/>
            </Box>
            <Box display="flex" justifyContent="flex-end">
                <Skeleton sx={{ mx: 0.5 }} variant="text" width={80} height={50} />
                <Skeleton sx={{ mx: 0.5 }} variant="text" width={80} height={50} />
            </Box>
        </Box> 
    );
};

export const CreateVehicleGroupCSVSkeleton = () => {
    return (
        <Box sx={{ mx: 3, my: 2 }}>
            <Skeleton variant="text" width={250} height={40} />
            <Box sx={{ my: 2 }}>
                <Skeleton sx={{ mb: 1 }} variant="text" width={200} height={34} />
                <Skeleton variant="rect" height={400} />
            </Box>
        </Box>
    );
};