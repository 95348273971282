import React from 'react';
import { useTheme } from '@mui/material/styles';
import { Card, CardContent, Typography } from "@mui/material";

const InfoTile = (props) => {
    const { title='', info='' } = props;
    const theme = useTheme();

    return (
        <Card>
            <CardContent className="tile-cards">
                <Typography color="text.secondary">
                    {title}
                </Typography>
                <Typography
                    className="tile-number"
                    color={theme.palette.primary['A700']}
                    sx={{ fontSize: 30, fontWeight: 400 }}
                >
                    {info}
                </Typography>
            </CardContent>
        </Card>
    );
}

export default InfoTile;