import React, { Fragment } from "react";
import { Grid } from "@mui/material";
import { useQuery } from "react-query";
import { getStatsForDashboard } from "../../services/Dashboard";
import InfoTile from "../common/InfoTile";

const DeploymentStats = () => {
  const { data: { deployments = 0, deploymentsInprogress = 0, deploymentsCompleted = 0 } = {} } = useQuery(
    "getStatsForDashboard",
    getStatsForDashboard,
    {
      refetchOnWindowFocus: false,
      useErrorBoundary: false,
      suspense: true,
    }
  );

  return (
    <Fragment>
      <Grid item xs={6} md={2}>
        <InfoTile title={"Total Deployments"} info={deployments} />
      </Grid>

      <Grid item xs={6} md={2}>
        <InfoTile title={"Completed Deployments"} info={deploymentsCompleted} />
      </Grid>

      <Grid item xs={6} md={2}>
        <InfoTile title={"In-progress Deployments"} info={deploymentsInprogress} />
      </Grid>
    </Fragment>
  );
};

DeploymentStats.defaultProps = {};

DeploymentStats.propTypes = {};

export default DeploymentStats;
