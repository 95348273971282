import React from "react";
import { ValueChicklet } from "./ValueChicklet";
// import CloseIcon from "@mui/icons-material/Close";
import CancelIcon from "@mui/icons-material/Cancel";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import { formatDisplayDatesForSearch } from "../../../services/Utils";
import SearchInput from "./SearchInput";

export function SearchItems({
  itemInputRef,
  index,
  searchKey,
  operator,
  searchVal,
  onItemRemoved,
  onItemEdited,
  mode,
  activeItemIndex,
  querySearch,
  searchInputProps,
  onEditCancel,
}) {
  if (searchVal) {
    if (operator.value === "between") {
      searchVal = searchVal.value;
      searchVal = formatDisplayDatesForSearch(searchVal);
    } else if (searchVal.length > 0) {
      searchVal = searchVal.map((o) => o.value).join(",");
    } else {
      searchVal = searchVal.value;
    }
  }

  return (
    <div
      className={
        (searchVal && operator.value && index !== activeItemIndex) ||
        !querySearch
          ? "searchItem"
          : "searchIncomplete"
      }
      style={{ height: "32px", position: "relative" }}
    >
      <div className="key" style={{ fontSize: "small", height: "100%" }}>
        {searchKey}
      </div>

      {operator.value?.length > 0 && (
        <div className="operator" style={{ fontSize: "small", height: "100%" }}>
          {operator.value}
        </div>
      )}

      {activeItemIndex !== index && (
        <ValueChicklet
          index={index}
          mode={mode}
          text={searchVal}
          onEditClicked={onItemEdited}
        />
      )}
      {(!operator?.value || !searchVal || activeItemIndex === index) && (
        <SearchInput {...searchInputProps} inputRef={itemInputRef} />
      )}

      {mode !== "editValue" && (
        <CancelIcon
          color="primary"
          onClick={(event) => {
            event.stopPropagation();
            onItemRemoved(index);
          }}
          sx={{
            height: "18px",
            width: "18px",
            cursor: "pointer",
            position: "absolute",
            right: "-9px",
            top: "-10px",
            borderRadius: "50%",
            backgroundColor: "white",
          }}
        />
      )}
      {mode === "editValue" && activeItemIndex === index && (
        <ModeEditIcon
          color="primary"
          onClick={(event) => {
            event.stopPropagation();
            onEditCancel();
          }}
          sx={{
            padding: "2px",
            color: "white",
            height: "16px",
            width: "16px",
            cursor: "pointer",
            position: "absolute",
            right: "-9px",
            top: "-10px",
            borderRadius: "50%",
            backgroundColor: "primary.main",
          }}
        />
      )}
    </div>
  );
}
